import React, { FC } from 'react';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Station } from '../../../models/station';

interface StationGridProps {
  stations: Station[],
  onStationDelete: (station: Station) => void
  onStationRun: (station: Station) => void
}

const StationGrid: FC<StationGridProps> = ({
  stations,
  onStationDelete,
  onStationRun
}) => {
  const stationTemplate = (station: Station) => {
    return (
      <div className="row align-items-center">
        <div className="col">
          <div className="fw-bold">{ station.stationName }</div>
          <div className={ station.connected ? 'text-success' : 'text-danger' }>
            { station.connected ? 'Online' : 'Offline' }
          </div>
        </div>

        <div className="col">
          <div>{ station.zones } Zones</div>
        </div>

        <div className="col row p-0 me-3" style={{ maxWidth: '100px' }}>
          <div className="col p-0 me-3">
            <Button
              icon="pi pi-stopwatch"
              aria-label="Run Manual"
              title="Run Manual"
              className="p-button-info"
              style={{borderRadius: '50%', height: '2rem', width: '2rem'}}
              onClick={() => onStationRun(station)}
            >
            </Button>
          </div>
          <div className="col p-0">
            <Button
              icon="pi pi-trash"
              aria-label="Delete Station"
              title="Delete Station"
              className="p-button-danger"
              style={{borderRadius: '50%', height: '2rem', width: '2rem'}}
              onClick={() => onStationDelete(station)}
            >
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DataView
        value={stations}
        layout={'grid'}
        itemTemplate={stationTemplate}
      />
    </div>
  )
};

export default StationGrid;
