import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import './login.scss';
import { Link, useNavigate } from 'react-router-dom';
import { auth, logInWithEmailAndPassword, logout, signInWithGoogle } from '../../firebase';
import { AuthContext } from '../../contexts/auth-context';
import moment from 'moment';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const { setAccessToken } = useContext(AuthContext);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((signedInUser) => {
      const tokenExparation = localStorage.getItem('expireTime') ? +localStorage.getItem('expireTime')! : 0;

      if (localStorage.getItem('googleToken') && moment().unix() < tokenExparation) {
        setAccessToken(localStorage.getItem('googleToken'));
      } else if (moment().unix() > tokenExparation) {
        logout();
      }
    });

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  });

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      navigate('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const googleLoginClicked = () => {
    signInWithGoogle().then(() => {
      const tokenExparation = localStorage.getItem('expireTime') ? +localStorage.getItem('expireTime')! : 0;

      if (localStorage.getItem('googleToken') && moment().unix() < tokenExparation) {
        setAccessToken(localStorage.getItem('googleToken'));
      } else if (moment().unix() > tokenExparation) {
        console.log('logout');
        logout();
      }
    });
  };
 
  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={googleLoginClicked}>
          Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}

export default Login;
