import React, { FC, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { StationDTO } from '../../models/station';
import { InputNumber } from 'primereact/inputnumber';
import { Nullable } from 'primereact/ts-helpers';

interface AddStationProps {
  visible: boolean;
  setVisible: (visable: boolean) => void;
  onStationCreated: (station: StationDTO) => void;
}

const AddStation: FC<AddStationProps> = ({ visible, setVisible, onStationCreated }) => {
  const [macAddress, setMacAddress] = useState<string>('');
  const [stationName, setStationName] = useState<string>('');
  const [zones, setZones] = useState<Nullable<number | null>>(0);

  const addClicked = () => {
    const station: StationDTO = {
      macAddress: macAddress,
      numberoFZones: zones ?? 0,
      stationName: stationName
    };

    onStationCreated(station);
  }

  const footer = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-outlined p-button-secondary" />
        <Button
          label="Add"
          icon="pi pi-check p-button-success"
          className="p-button-success ms-2"
          onClick={() => addClicked()}
          disabled={(macAddress && stationName && zones) ? false : true}
        />
      </div>
    )
  }

  return (
    <Dialog
      header="Add New Station"
      footer={footer()}
      visible={visible}
      onHide={() => setVisible(false)}
      style={{width: '20rem'}}
    >
      <div style={{marginTop: '1.25rem'}}>
        <span className="p-float-label">
          <InputText id="macAddress" value={macAddress} onChange={(e) => setMacAddress(e.target.value)} />
          <label htmlFor="macAddress">MAC Address</label>
        </span>
      </div>

      <div style={{marginTop: '1.75rem'}}>
        <span className="p-float-label">
          <InputText id="stationName" value={stationName} onChange={(e) => setStationName(e.target.value)} />
          <label htmlFor="stationName">Station Name</label>
        </span>
      </div>

      <div style={{marginTop: '1.75rem'}}>
        <span className="p-float-label">
          <InputNumber inputId="minmax" value={zones} onValueChange={(e) => setZones(e.value)} mode="decimal" min={0} max={12} />
          <label htmlFor="zones">Number of Zones</label>
        </span>
      </div>
    </Dialog>
  )
};

export default AddStation;
