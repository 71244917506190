import React, { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Station } from '../../models/station';
import { InputNumber } from 'primereact/inputnumber';
import { Schedule } from '../../models/schedule';

interface RunScheduleProps {
  visible: boolean;
  setVisible: (visable: boolean) => void;
  station: Station;
  runStation: (station: Station, schedule: Schedule[]) => void;
}

const RunSchedule: FC<RunScheduleProps> = ({ visible, setVisible, station, runStation }) => {
  const [runTimes, setRunTimes] = useState<number[]>([]);

  useEffect(() => {
    const zoneRunTimes: number[] = [];

    for (let i = 0; i < station.zones; i++) {
      zoneRunTimes.push(0);
    }

    setRunTimes([...zoneRunTimes]);
  }, [station.zones]);

  const runSchedule = () => {
    const scheduleToRun: Schedule[] = [];

    runTimes.forEach((minutes, zone) => {
      scheduleToRun.push({
        zone: zone + 1,
        minutes: minutes
      });
    });

    runStation(station, scheduleToRun);
  };

  const footer = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-outlined p-button-secondary" />
        <Button
          label="Run"
          icon="pi pi-check p-button-success"
          className="p-button-success ms-2"
          onClick={() => runSchedule()}
        />
      </div>
    )
  };

  const updateRunTime = (zoneIndex: number, minutes: number | null) => {
    if (minutes !== null) {
      const runTimesCopy = [...runTimes];

      runTimesCopy[zoneIndex] = minutes;

      setRunTimes(runTimesCopy);
    }
  };

  return (
    <Dialog
      header={`Run Schedule for ${station.stationName}`}
      headerClassName="text-center"
      footer={footer()}
      visible={visible}
      onHide={() => setVisible(false)}
      style={{width: '20rem'}}
    >
      <div className="d-flex flex-column justify-content-center">
        {[...Array(station.zones)].map((x, i) =>
          <div style={{marginTop: '1rem'}}>
            <label className="me-2" htmlFor={`zone-${i}`}>Zone {i + 1}</label>
            <InputNumber id={`zone-${i}`} value={runTimes[i]} onChange={(e) => updateRunTime(i, e.value)} className="schedule-input" inputClassName="w-100 text-end p-1" />
          </div>
        )}
      </div>
    </Dialog>
  )
};

export default RunSchedule;
