import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './dashboard.scss';
import { auth, db, logout } from '../../firebase';
import { query, collection, getDocs, where } from 'firebase/firestore';
import { AuthContext } from '../../contexts/auth-context';
import moment from 'moment';
import axios from 'axios';
import StationGrid from './station-grid/station-grid';
import { Station, StationDTO } from '../../models/station';
import AddStation from './add-station';
import { Button } from 'primereact/button';
import RunSchedule from './run-station';
import { Schedule } from '../../models/schedule';

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState('');
  const [addStationVisible, setAddStationVisible] = useState(false);
  const [deleteStationVisible, setDeleteStationVisible] = useState(false);
  const [runStationVisible, setRunStationVisible] = useState(false);
  const [connection, setConnection] = useState<signalR.HubConnection>();
  const [stations, setStations] = useState<Station[]>([]);
  const [stationToDelete, setStationToDelete] = useState<Station>();
  const [stationToRun, setStationToRun] = useState<Station>();

  const { accessToken, setAccessToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  useEffect(() => {
    if (accessToken && (!connection || connection.state === signalR.HubConnectionState.Disconnected)) {
      setConnection(new signalR.HubConnectionBuilder()
      .withUrl(`https://services.nensters.com/sprinklerHub`, {
        accessTokenFactory: () => accessToken,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (connection && connection.state === signalR.HubConnectionState.Disconnected) {
      connection.start()
        .catch((err: any) => console.error(err));
      }
  }, [connection]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!user) {
      return navigate('/');
    }

    const checkToken = async () => {
      const tokenExparation = localStorage.getItem('expireTime') ? +localStorage.getItem('expireTime')! : 0;

      if (localStorage.getItem('googleToken') && moment().unix() < tokenExparation) {
        setAccessToken(localStorage.getItem('googleToken'));
      } else {
        const newToken = await auth.currentUser?.getIdToken(true);
        localStorage.setItem('googleToken', newToken ? newToken : '');
        localStorage.setItem('expireTime', (moment().unix() + 3599).toString());
        setAccessToken(newToken);
      }
    }
    
    checkToken().then(() => {
      fetchUserName();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  useEffect(() => {
    if (name && accessToken) {
      const headers = {
        'Authorization': `Bearer ${accessToken}`
      };
      axios.get<Station[]>('https://services.nensters.com/api/Sprinkler/AllStations', { headers })
          .then((response) => {
            setStations(response.data);
          });
    }
  }, [name, accessToken]);

  const addStation = (station: StationDTO) => {
    if (accessToken) {
      const headers = {
        'Authorization': `Bearer ${accessToken}`
      };
      axios.post<Station>('https://services.nensters.com/api/Sprinkler/Station', station, { headers })
          .then((response) => {
            const existingStations = [...stations];

            existingStations.push(response.data);
            setStations(existingStations);
            setAddStationVisible(false);
          });
    }
  };

  const acceptDeleteStation = () => {
    if (accessToken && stationToDelete) {
      const headers = {
        'Authorization': `Bearer ${accessToken}`
      };
      axios.delete<boolean>(`https://services.nensters.com/api/Sprinkler/Station/${stationToDelete.stationMac}`, { headers })
        .then((response) => {
          if (response.status === 200) {
            const existingStations = [...stations];

            const deletedIndex = existingStations.findIndex((s) => s.stationMac === stationToDelete.stationMac);

            if (deletedIndex >= 0) {
              existingStations.splice(deletedIndex, 1);
              setStations(existingStations);
            }
          }
        });
    }
  }

  const deleteStation = (station: Station) => {
    setStationToDelete(station);
    setDeleteStationVisible(true);
  };

  const openScheduleDialog = (station: Station) => {
    setStationToRun(station);
    setRunStationVisible(true);
  };

  const sendScheduleToHub = async (station: Station, schedule: Schedule[]) => {
    if (connection) {
      const result = await connection.invoke<boolean>('runZones', station.stationMac, JSON.stringify(schedule));

      console.log(result);
    }
  };

  const openAddStation = () => {
    setAddStationVisible(true);
  }

  return (
    <div>
      <div className="header d-flex flex-row-reverse">
          <button className="dashboard__btn" onClick={logout}>
            Logout
          </button>
          <div>{name}</div>
        </div>
      <div className="dashboard">
        <div className="dashboard__container">
          <div className="fs-2 fw-bold">Stations</div>
          <div className="mt-3">
            <div className="w-100 text-end mb-3">
              <Button
                icon="pi pi-plus"
                aria-label="Add Station"
                title="Add Station"
                className="p-button-success"
                style={{borderRadius: '50%', height: '2rem', width: '2rem'}}
                onClick={openAddStation}
              >
              </Button>
            </div>
            <StationGrid stations={stations} onStationDelete={deleteStation} onStationRun={openScheduleDialog}></StationGrid>
            {/* <button className="dashboard__btn" onClick={openAddStation}>
              Add Station
            </button> */}
          </div>
        </div>
      </div>

      <AddStation visible={addStationVisible} setVisible={setAddStationVisible} onStationCreated={addStation}></AddStation>
      {stationToRun
        ? (<RunSchedule visible={runStationVisible} setVisible={setRunStationVisible} station={stationToRun} runStation={sendScheduleToHub}></RunSchedule>)
        : null
      }
      <ConfirmDialog
        visible={deleteStationVisible}
        onHide={() => setDeleteStationVisible(false)}
        message={`Are you sure you want to delete ${stationToDelete?.stationName}?`}
        header="Confirm Deletion"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-info"
        rejectClassName="p-button-outlined p-button-info me-3"
        accept={acceptDeleteStation}
        reject={() => {}}
      />
    </div>
  );
}

export default Dashboard;
