import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import { AuthContext } from './contexts/auth-context';

function App() {
  const [accessToken, setAccessToken] = useState('');
  const value = {accessToken, setAccessToken};

  return (
    <div className='app'>
      <AuthContext.Provider value={value}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />}></Route>
            <Route path='/dashboard' element={<Dashboard />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
