import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from 'firebase/firestore';
import moment from 'moment';

const firebaseConfig = {
  apiKey: 'AIzaSyDtmx5zv7wBXLGzVcmQI4FJmn1NysunjiM',
  authDomain: 'sprinkler-controller-a938d.firebaseapp.com',
  projectId: 'sprinkler-controller-a938d',
  storageBucket: 'sprinkler-controller-a938d.appspot.com',
  messagingSenderId: '850365626015',
  appId: '1:850365626015:web:d48605541fb8038d6b54aa',
  measurementId: 'G-CQP0DGJKCB'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {

    // TODO: May need to change this from await to .then
    signInWithPopup(auth, googleProvider).then(async (result) => {
      const token = await auth.currentUser?.getIdToken();

      console.log(token);
      // if (token) {
      localStorage.setItem('googleToken', token ? token : '');
      localStorage.setItem('refreshToken', (result as any)._tokenResponse.refreshToken);
      localStorage.setItem('expireTime', (moment().unix() + 3599).toString());
      // }

      const user = result.user;
      const q = query(collection(db, 'users'), where('uid', '==', user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, 'users'), {
          uid: user.uid,
          name: user.displayName,
          authProvider: 'google',
          email: user.email,
        });
      }
    });
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    });
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};